import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default function useAlert() {
  function alertToastSuccess(opts = {}) {
    const { title = "Succès", timer = 2000 } = opts;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: true,
    });

    return Toast.fire({
      icon: "success",
      title: title,
    });
  }

  function alertConfirm(opts = {}) {
    const {
      icon = "question",
      title = "Confirmer",
      text = "Êtes-vous sûr\u00A0?",
      confirmButtonText = "Oui",
      denyButtonText = "Non",
      html = "",
      input = "",
      inputClass = "form-control",
      inputPlaceholder = "",
      inputOptions,
      inputValidator,
      focusConfirm,
      preConfirm,
    } = opts;

    const commonButtonsStyle = "btn rounded-pill btn-lg px-4 mx-3 mb-3";
    return Swal.fire({
      title,
      text,
      html,
      icon,
      confirmButtonText,
      denyButtonText,
      showDenyButton: true,
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: `${commonButtonsStyle} btn-primary`,
        denyButton: `${commonButtonsStyle} btn-light`,

        input: inputClass,
      },

      input,
      inputPlaceholder,
      inputOptions,

      inputValidator,

      focusConfirm,
      preConfirm,
    });
  }

  function alertError(opts = {}) {
    const {
      title = "Erreur",
      text = "Quelque chose s'est mal passé",
      showConfirmButton = true,
      confirmButtonText = "OK",
      timer = undefined,
    } = opts;

    return Swal.fire({
      title,
      text,
      icon: "error",
      showConfirmButton,
      confirmButtonText,
      timer,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn rounded-pill btn-lg px-4 btn-danger",
      },
    });
  }

  function alertInfo(opts = {}) {
    const {
      title = "",
      text = "",
      html = "",
      showConfirmButton = true,
      confirmButtonText = "OK",
      timer = undefined,
    } = opts;

    return Swal.fire({
      title,
      text,
      html,
      icon: "info",
      showConfirmButton,
      confirmButtonText,
      timer,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn rounded-pill btn-lg px-4 btn-info",
      },
    });
  }

  function alertWarning(opts = {}) {
    const {
      title = "",
      text = "",
      html = "",
      showConfirmButton = true,
      showCancelButton = true,
      confirmButtonText = "OK",
      cancelButtonText = "Cancel",
    } = opts;

    return Swal.fire({
      title,
      text,
      html,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      customClass: {
        confirmButton: "btn rounded-pill btn-lg px-4 btn-info",
        cancelButton: "btn rounded-pill btn-lg px-4 btn-danger",
      },
    });
  }

  return {
    alertToastSuccess,
    alertConfirm,
    alertError,
    alertInfo,
    alertWarning,
  };
}
